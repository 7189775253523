import React from "react";
import { Search } from "../components/select-search/select-search";
import Pagination from "../components/pagination/pagination";
import items_identified from "../data/items_identified.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./style.css";
import Helmet from "react-helmet";

function compare_name(a, b) {
  if (a.name < b.name) {
    return -1;
  } else if (a.name > b.name) {
    return 1;
  } else {
    return 0;
  }
}

var markalar = [...new Set(Object.values(items_identified).map(item => item["marka"]))].map((item) => {
  return {
    value: item,
    name: item.toUpperCase(),
  };
});
markalar.sort(compare_name);

markalar.unshift({ value: "all", name: "Tüm Markalar" });

var modeller = [{ value: "all", name: "Tüm Modeller" }];
var parcalar = [...new Set(Object.values(items_identified).map(item => item["parca_tipi"]))].map((item) => {
  return {
    value: item,
    name: item.toUpperCase(),
  };
});
parcalar.sort(compare_name);
parcalar.unshift({ value: "all", name: "Tüm Parcalar" });

class HomeScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: Object.values(items_identified).reverse(),
      interFilter: Object.values(items_identified).reverse(),
      afterFilter: Object.values(items_identified).reverse(),
      searchBarValue: "",
    };
    this.selected = {
      marka: "all",
      model: "all",
      parca: "all",
    };
  }


  filterMarka(marka) {
    this.state.searchBarValue = "";
    if(marka==="all") {
      this.state.afterFilter = this.state.items;
      modeller = [{ value: "all", name: "Tüm Modeller" }];
    } else {
      this.state.afterFilter = this.state.items.filter(item => item["marka"] === marka);

      modeller = [...new Set(this.state.afterFilter.map(item => item["model"]))].map((item) => {
        return {
          value: item,
          name: item.toUpperCase(),
        };
      });
      modeller.sort(compare_name);
      modeller.unshift({ value: "all", name: "Tüm Modeller" })

    }
    this.selected.marka = marka;
    this.selected.model = "all";
    this.selected.parca = "all";
    this.state.interFilter = this.state.afterFilter;
    this.updateParcalar();
    this.forceUpdate()
  }

  filterModel(model) {
    this.filterMarka(this.selected.marka)
    if(model==="all") {
      this.state.afterFilter = this.state.interFilter;
    } else {
      this.state.afterFilter = this.state.interFilter.filter(item => item["model"] === model);
    }
    this.selected.model = model;
    this.state.interFilter = this.state.afterFilter;
    this.updateParcalar();
    this.forceUpdate();
  }

  filterParca(parca) {
    var selectedModel = this.selected.model;
    this.filterMarka(this.selected.marka)
    this.filterModel(selectedModel)
    if(parca==="all") {
      this.state.afterFilter = this.state.interFilter;
    } else {
      this.state.afterFilter = this.state.interFilter.filter(item => item["parca_tipi"] === parca);
    }
    this.selected.parca = parca;
    this.state.interFilter = this.state.afterFilter;
    this.forceUpdate();
  }

  onSearchBarChange(text) {
    this.state.searchBarValue = text;
    var wordlist = text.split(" ");
    var filtered = this.state.interFilter;
    var word;
    for (word of wordlist) {
      filtered = filtered.filter((item) => 
        item.baslik.replaceAll("İ", "i").replaceAll("I","ı").toLowerCase().includes(word.toLowerCase())
      );
    }

    this.state.afterFilter = filtered;
    this.forceUpdate();
  }

  updateParcalar() {
    parcalar = [...new Set(this.state.afterFilter.map(item => item["parca_tipi"]))].map((item) => {
      return {
        value: item,
        name: item.toUpperCase(),
      };
    });
    parcalar.sort(compare_name);
    parcalar.unshift({ value: "all", name: "Tüm Parcalar" });

  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>Sağlam Çıkma Parça - Tüm Araçların Orjinal Çıkma Parçaları</title>
          <meta name="description" content="Tüm Araçların Orjinal Çıkma Parçaları En Uygun Fiyatlarla Tüm Türkiye'ye Kargo İle Gönderilir" />
        </Helmet>
        <Row className="justify-content-left mt-5">
          <Col lg={4} xs={7} className="my-2">
            <Search
              options={markalar}
              text={"Marka"}
              onSelect={(marka) => this.filterMarka(marka)}
              selected={this.selected.marka}
            />
          </Col>
          <Col lg={4} xs={7} className="my-2">
            <Search
              options={modeller}
              text={"Model"}
              onSelect={(model) => this.filterModel(model)}
              selected={this.selected.model}
            />
          </Col>
          <Col lg={4} xs={7} className="my-2">
            <Search
              options={parcalar}
              text={"Parça"}
              onSelect={(parca) => this.filterParca(parca)}
              selected={this.selected.parca}
            />
          </Col>
        </Row>
        <Row className="justify-content-left search-bar mt-2">
          <input
            className="select-search__input2"
            type="text"
            placeholder="Arama Çubuğu"
            onChange={(e) => this.onSearchBarChange(e.target.value)}
            value={this.state.searchBarValue}
          />
        </Row>
        <Pagination data={this.state.afterFilter} />
      </React.Fragment>
    );
  }
}

export default HomeScreen;
