import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Helmet from "react-helmet";

class ContactScreen extends React.Component {

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>İletişim - Sağlam Çıkma Parça</title>
          <meta name="description" content={"İletişim - Sağlam Çıkma Parça, en uygun fiyatlarla tüm Türkiye'ye kargo ile gönderilir"} />
        </Helmet>
        {/*<Row className="mt-5">
          <Col className="text-center font-weight-bold details">
            <p className="text-danger"><u>!!KESİN FİYAT BİLGİSİ İÇİN BİZİMLE İRTİBATA GEÇİNİZ!!</u></p>
            <p>ORJİNAL ÇIKMA YEDEK PARÇA ÇEŞİTLERİMİZLE HİZMETİNİZDEYİZ.</p>
            <p>SİPARİŞLERİNİZ KARGO VE AMBAR İLE</p>
            <p>TÜRKİYE NİN HER BÖLGESİNE ULAŞTIRILIR.</p>
            <p>KARGO ALICIYA AİTTİR.</p>
            <p className="text-danger"><u>LÜTFEN İLAN BAŞLIĞINDAKİ OEM NUMARASINI KONTROL EDİNİZ.</u></p>
            <p className="text-danger"><u>ÜRÜNLERİMİZDE KESİNLİKLE İADE VE DEĞİŞİM YOKTUR.</u></p>
            <p>ÜRÜNLERİMİZ HAKKINDA DETAYLI BİLGİ ALMAK İÇİN VE KULLANICI </p>
            <p>MAĞAZAMIZDA BULAMADIĞINIZ ÜRÜNLER İÇİN BİZE ULAŞABİLİRSİNİZ. </p>
            <p className="text-danger"><u>0552 654 85 15 - 0551 974 85 15 - 0332 235 30 58</u></p>
          </Col>
    </Row>*/}
      </React.Fragment>
    );
  }
}

export default ContactScreen;
