import React from "react";
import BrowserRouter from "react-router-dom/BrowserRouter";
import Route from "react-router-dom/Route";
import DetailScreen from "./Screens/DetailScreen";
import HomeScreen from "./Screens/HomeScreen";
import ContactScreen from "./Screens/ContactScreen";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Link from "react-router-dom/Link";
import { Form, FormControl, Nav, Navbar, NavDropdown, OverlayTrigger } from "react-bootstrap";
import "./App.css";
import ScrollToTop from "./components/ScrollToTop";
import Tooltip from "react-bootstrap/Tooltip";


class App extends React.Component {
  whatsapp() {
    let link = "https://wa.me/905519748515";
    if (window.location.pathname === "/") {
      window.open(link);
    } else {
      link =
        link +
        "?text=Merhaba,%0aBu ürün hakkında bilgi almak istiyorum.%0a" +
        window.location.href;
      window.open(link);
    }
  }

  render() {
    return (
      <React.Fragment>
        <OverlayTrigger
          placement="left"
          delay={{ show: 100, hide: 200 }}
          overlay={<Tooltip id="button-tooltip">Bizi Arayın</Tooltip>}
        >
          <a className="phone" href="tel:+905519748515">
            <Image
              className="img-fluid"
              src={require("./phone.png")}
              alt="phone"
              roundedCircle
            ></Image>
          </a>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          delay={{ show: 100, hide: 200 }}
          overlay={
            <Tooltip id="button-tooltip">WhatsApp'tan Bize Ulaşın</Tooltip>
          }
        >
          <a className="whatsapp" onClick={() => this.whatsapp()}>
            <Image
              className="img-fluid"
              src={require("./WhatsApp.png")}
              alt="whatsapp"
            ></Image>
          </a>
        </OverlayTrigger>
        <Container>
          <BrowserRouter>
            <Row className="mb-5 bg-secondary text-center">
              <Col xs={12} sm={"auto"}>
                <Link to="/">
                  <Image
                    src={require("./logo.jpg")}
                    alt="logo"
                    height={200}
                  />
                </Link>
              </Col>
              <Col className="align-self-center">
                <Navbar className="justify-content-center" expand="lg">
                  <Navbar.Toggle aria-controls="basic-navbar-nav" />
                  <Navbar.Collapse>
                    <Nav className="mr-auto">
                      <Nav.Link href="/">Anasayfa</Nav.Link>
                      <Nav.Link href="/iletisim">İletişim</Nav.Link>
                      <Nav.Link href="https://saglamotomotivyedekparca.sahibinden.com/" target="_blank">Sahibinden Mağazamız</Nav.Link>
                      <Nav.Link href="https://www.otocikma.com/kurumsal_uye_profil-saglam_otomotiv_cikma_yedek_parca-58089.html" target="_blank">Oto Çıkma Mağazamız</Nav.Link>
                    </Nav>
                    <Navbar.Brand>0551 974 85 15</Navbar.Brand>
                  </Navbar.Collapse>
                </Navbar>
              </Col>
            </Row>
            <ScrollToTop />
            <Route
              path="/details/:baslik"
              component={DetailScreen}
              onUpdate={() => window.scrollTo(0, 0)}
            />
            <Route
              path="/"
              exact={true}
              component={HomeScreen}
              onUpdate={() => window.scrollTo(0, 0)}
            />
            <Route
              path="/iletisim"
              exact={true}
              component={ContactScreen}
              onUpdate={() => window.scrollTo(0, 0)}
            />
          </BrowserRouter>
        </Container>
        <Navbar sticky="bottom" expand="lg" className="mt-4 footer">
          <Container>
            <Row className="mt-3 flex-grow-1">
              <Col>
                <h5>Adres:</h5>
                <p>Fatih Mahallesi, Kılınç Sokak</p>
                <p>No:23, Selçuklu/KONYA</p>
                <h5>Telefon:</h5>
                <p>0551 974 85 15</p>
                <p>0552 654 85 15</p>
                <p>0332 235 30 58</p>
              </Col>
              <Col className="gmap" sm={7}>
                <iframe
                  frameborder="0"
                  src="https://www.google.com/maps/embed/v1/place?key=AIzaSyBGkucWqophK67MZ07pjRYB2zXQ8WCMgNo&center=37.906592,32.499739&q=Sağlam Otomotiv Çıkma Yedek Parça"
                ></iframe>
              </Col>
            </Row>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default App;
