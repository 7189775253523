import React from "react";
import items_identified from "../data/items_identified.json";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from 'react-bootstrap/Table';
import Helmet from "react-helmet";

class DetailScreen extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      item: items_identified[this.props.match.params.baslik.split("-")[0]]
    };
  }

  render() {
    return (
      <React.Fragment>
        <Helmet>
          <title>{this.state.item.baslik.replace("OEM;", "OEM; ")} - Sağlam Çıkma Parça</title>
          <meta name="description" content={this.state.item.baslik + " en uygun fiyatlarla tüm Türkiye'ye kargo ile gönderilir"} />
        </Helmet>
        <Row><Col>
          <h4 className="test">{this.state.item.baslik}</h4>
        </Col></Row>
        <Row><Col>
          <p className="test2">{this.state.item.kategori}</p>
        </Col></Row>
        <Row>
          <Col md={8} className="mt-4">
            <Image
              className="img-fluid"
              src={this.state.item.image_url}
              alt={this.state.item.baslik}
            />
          </Col>
          <Col className="mt-4">
            <Table bordered striped hover size="sm">
              <tbody>
                <tr>
                  <td><p>Marka:</p></td>
                  <td><p>{this.state.item.marka}</p></td>
                </tr>
                <tr>
                  <td><p>Model:</p></td>
                  <td><p>{this.state.item.model}</p></td>
                </tr>
                <tr>
                  <td><p>Parça Tipi:</p></td>
                  <td><p>{this.state.item.parca_tipi}</p></td>
                </tr>
                <tr>
                  <td><p>Parça:</p></td>
                  <td><p>{this.state.item.parca}</p></td>
                </tr>
                <tr>
                  <td><p>OEM:</p></td>
                  <td><p>{this.state.item.oem}</p></td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="text-center font-weight-bold details">
            <p className="text-danger"><u>!!KESİN FİYAT BİLGİSİ İÇİN BİZİMLE İRTİBATA GEÇİNİZ!!</u></p>
            <p>ORJİNAL ÇIKMA YEDEK PARÇA ÇEŞİTLERİMİZLE HİZMETİNİZDEYİZ.</p>
            <p>SİPARİŞLERİNİZ KARGO VE AMBAR İLE</p>
            <p>TÜRKİYE NİN HER BÖLGESİNE ULAŞTIRILIR.</p>
            <p>KARGO ALICIYA AİTTİR.</p>
            <p className="text-danger"><u>LÜTFEN İLAN BAŞLIĞINDAKİ OEM NUMARASINI KONTROL EDİNİZ.</u></p>
            <p className="text-danger"><u>ÜRÜNLERİMİZDE KESİNLİKLE İADE VE DEĞİŞİM YOKTUR.</u></p>
            <p>ÜRÜNLERİMİZ HAKKINDA DETAYLI BİLGİ ALMAK İÇİN VE KULLANICI </p>
            <p>MAĞAZAMIZDA BULAMADIĞINIZ ÜRÜNLER İÇİN BİZE ULAŞABİLİRSİNİZ. </p>
            <p className="text-danger"><u>0552 654 85 15 - 0551 974 85 15 - 0332 235 30 58</u></p>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default DetailScreen;
