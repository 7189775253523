import React from "react";
import PaginationList from "react-pagination-list";
import "./style.css";
import Link from "react-router-dom/Link";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Table from "react-bootstrap/Table";

class Pagination extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Row>
        <PaginationList
          data={this.props.data}
          pageSize={20}
          renderItem={(item, key) => (
            <Col className="mb-4" md={12} lg={6}>
              <li key={item.id}>
                <Link to={"/details/" + item.id + "-" + item.baslik.replaceAll(" ", "-")}>
                  <div className="item d-flex flex-column">
                    <Row>
                      <Col>
                        <h5>{item.baslik}</h5>
                      </Col>
                    </Row>
                    <Row className="flex-grow-1 align-items-center">
                      <Col>
                        <Image
                          className="img-fluid"
                          src={item.thumb_url}
                          alt={item.baslik}
                        />
                      </Col>
                      <Col>
                        <Table striped borderless size="sm">
                          <tbody>
                            <tr>
                              <td>
                                <p>Marka:</p>
                              </td>
                              <td>
                                <p>{item.marka}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Model:</p>
                              </td>
                              <td>
                                <p>{item.model}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Parça Tipi:</p>
                              </td>
                              <td>
                                <p>{item.parca_tipi}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>Parça:</p>
                              </td>
                              <td>
                                <p>{item.parca}</p>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <p>OEM:</p>
                              </td>
                              <td>
                                <p>{item.oem}</p>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </div>
                </Link>
              </li>
            </Col>
          )}
        />
      </Row>
    );
  }
}

export default Pagination;
