import React from 'react';
import SelectSearch from 'react-select-search';
import './style.css';

export const Search = (props) => (
    <SelectSearch
        options={props.options}
        printOptions="on-focus"
        placeholder={props.text}
        closeOnSelect={true}
        onChange={(value) => props.onSelect(value)}
        value={props.selected}
    />
);

export default Search;